import React, { Component } from 'react';
// import keydown from 'react-keydown';
// import { onMount, onUnmount, setBinding } from 'react-keydown/dist/event_handlers';
import YouTube from 'react-youtube';
import './App.css';
import moviedata from './moviedata.json';
import {isMobile} from 'react-device-detect';

let movieIndex = 0;

const regex = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*)(?:(\?t|&start)=(\d+))?.*/;

const parsedMovieData = moviedata.map(parseYouTubeUrl);

function parseYouTubeUrl(movieObject) {
  const parsedUrlArray = regex.exec(movieObject.song);
  return {
    title: movieObject.title,
    song: parsedUrlArray[2],
    startTime: parsedUrlArray[4] ? parseInt(parsedUrlArray[4]) : null
  }
}

const youtubeOpts = {
  width: '0',
  height: '0',
  playerVars: {
    autoplay: 1,
    playsinline: 1,
    start: 0
  }
}

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      movie: parsedMovieData[movieIndex], 
      movieDisplayName: "???",
      helpText: 'Tap here to reveal',
      buttonText: 'Reveal',
      revealed: false,
      isMobile: false
    }
    this.goNext = this.goNext.bind(this);
  }

  goNext() {
    if (this.state.revealed) {
      movieIndex++
      this.setState(state => ({
        movie: parsedMovieData[movieIndex],
        movieDisplayName: '???',
        buttonText: 'Reveal',
        revealed: false  
      }));
      return;
    }
    else {
      this.setState(state => ({
        movie: state.movie,
        movieDisplayName: state.movie.title,
        buttonText: 'Next Song',
        revealed: true
      }));  
    }
  }

  render() {
    if (this.state.movie.startTime) {
      youtubeOpts.playerVars.start = this.state.movie.startTime;
    }

    if (isMobile) {
      return (
        <div className="App">
          <div className="header">
            Tonal Recall
          </div>
          <div className="mobileMessage">
            Unfortuntely this game only works <br></br>on desktop 😭
          </div>
        </div>
      )
    }

    return (
      <div className="App" onKeyPress={this.handleKeyPress}>
        <div className="header">
          Tonal Recall
        </div>
        <div className='movie-view'>
          <div className='movie-name'>
            {this.state.movieDisplayName}
          </div>
          <div className="song-player">
            <YouTube
              videoId={this.state.movie.song}
              opts={youtubeOpts}
            >
            </YouTube>
          </div>
        </div>
        <div className='next-button' onClick={this.goNext}>
          {this.state.buttonText}
        </div>
      </div>
    );
  }
}

export default App;

/* TODO:

- add ability to pause/mute
- add space bar shortcut to trigger "next"
- update favicon
- divide list of songs into decks
- add routing, so people can pick up where they left off

*/


/*

google client id: 119005404603-pkqoop18f3eqofck264de2tol86e2li7.apps.googleusercontent.com

google client secret: XMAVOSRK5O9RWHgtNAn6oQJw

*/